<template>
    <div class="profile_notes">
        <div class="title mb-3">
            <ul class="tab_list">
                <li @click="tab = 1" :class="{ active: tab === 1 }">SMS</li>
                <li @click="tab = 2" :class="{ active: tab === 2 }">Portal Chat</li>
            </ul>
        </div>
        <chat-component :contact="contact" :mark-read="handleMarkAsReadUnread" filter="unread" v-if="tab == 1" />
        <portal-chat :contact="contact" v-if="tab == 2" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const ChatComponent = defineAsyncComponent(() => import('@/pages/sms-center/Chat'))
    const PortalChat = defineAsyncComponent(() => import('@/pages/contact/components/profile/components/PortalChat'))

    import Toastr from '@/utils/Toastr'

    export default{
        name: 'Tab Chat',

        props: {
            contact: Object,
            handleLaunchPad: Function,
        },

        components:{
            ChatComponent,
            PortalChat,
        },

        data(){
            return{
                tab: 1,
            }
        },

        methods: {
            ...mapActions({
                markContactAsStarred: 'contactModule/markContactAsStarred',
                updateMarkAsReadUnread: 'smsCenterModule/updateMarkAsReadUnread',
            }),

            handleMarkContactAsStarred () {
                const vm = this;

                vm.markContactAsStarred({ starred: 1, contact_id: vm.contact.id });
            },

            handleMarkAsReadUnread (value) {
                const vm = this;

                vm.updateMarkAsReadUnread({ value, contact_id: vm.contact.id }).then((result) => {
                    if (result.data != 0) {
                        const contact = JSON.parse(JSON.stringify(vm.contact));
                        contact.read = value;

                        // if (vm.$parent.$parent && vm.$parent.$parent.updateSelectedContact) {
                        //     vm.$parent.$parent.updateSelectedContact(contact);
                        // } else {
                        //     vm.$parent.updateSelectedContact(contact);
                        // }

                    } else {
                        Toastr.error('Mark as read/unread work only on inbound sms!');
                    }
                });
            }
        },
    }
</script>

<style scoped>
    .tab_list{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }
    .tab_list li{
        padding: 10px 20px;
        background: #fff;
        border-radius: 5px;
        font-size: 14px;
        line-height: 17px;
        cursor: pointer;
    }
    .tab_list li.active{
        background: #2f7eed;
        color: #fff;
    }
    /* .profile_notes{
        height: calc(100vh - 170px);
    } */
    .no-portal-chat{
        height: calc(100vh - 315px);
        background: #f2f5fc;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .no-portal-chat h2{
        font-size: 20px;
        line-height: 30px;
        max-width: 350px;
        text-align: center;
        color: #999;
        font-weight: 300;
    }
    .profile_notes :deep(.msg_board){
        margin-left: 0;
    }
    .profile_notes :deep(.chat_client img){
        display: block;
        margin: auto 0 15px 10px;
    }
    .profile_notes :deep(.chat_user img) {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
        margin: auto 10px 15px 0;
    }
    .profile_notes :deep(.chat_box textarea){
        background: #fff;
        /* border: 1px solid #e9e9e9; */
    }

    .profile_notes :deep(.rich_text_editor .ql-container.ql-snow){
        background: #fff;
    }

    .profile_notes :deep(.msg_board .chat){
        height: calc(100% - 165px);
    }

   :deep(.chat_box .emoji-picker .default-emoji-dropdown){
       /* bottom: 1px; */
        left: 25px;
        right: auto;
    }
    :deep(.chat_box .dropdown .drp_wrapper ul){
        scrollbar-width: auto;
    }
    :deep(.chat_box .dropdown .drp_wrapper ul::-webkit-scrollbar){
        width: 3px;
        display: block;
    }
    :deep(.chat_box .dropdown .drp_wrapper ul::-webkit-scrollbar-thumb){
        background: #dbdbdb;
        border-radius: 2px;
    }
    @media(max-width: 399px){
        .profile_notes :deep(.chat_box .schedule_btn) {
            position: absolute;
            right: 0;
            bottom: 75px;
            width: 35px;
            height: 35px;
            margin: 0;
        }
        .profile_notes :deep(.chat_box .post) {
            bottom: 35px;
        }
        .profile_notes :deep(.chat_box.onTinyScreen){
            margin-top: 0;
            position: relative;
        }
    }
</style>
